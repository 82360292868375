import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import config from "./config";

import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import User from "@/models/User";

Vue.use(Vuex);

// TODO: Can we do this better?
export default new Vuex.Store({
  modules: {
    auth,
    config,
  },
  plugins: [
    createPersistedState({
      paths: ["auth.session", "config.vueFrontendConfig"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            expires: 3,
            secure: process.env.NODE_ENV === "production",
          }),
        removeItem: (key) => Cookies.remove(key),
      },
      getState(key, storage, value) {
        try {
          var state =
            (value = storage.getItem(key)) && typeof value !== "undefined"
              ? JSON.parse(value)
              : undefined;

          // Re-Hydrate the currentUser object from the token string.
          // As Vuex doesn't persist the class.
          const newUser = User.from(state.auth.session.token);
          newUser.operations = state.auth.session.currentUser.operations;

          state.auth.session.currentUser = newUser;

          return state;

          // eslint-disable-next-line
        } catch (err) { }

        return undefined;
      },
    }),
  ],
});
