import Vue from "vue";
import vuetify from "./plugins/vuetify";
import "./plugins/filters";
import "./plugins/csv";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import configureInterceptors from "./backend/interceptors";
import axios from "./backend/axios";

import { mapGetters } from "vuex";

import setUpLeaflet from "./L";

setUpLeaflet();

Vue.config.productionTip = false;

configureInterceptors();

Vue.use(VueAxios, axios);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      vueFrontendConfig: "vueFrontendConfig",
    }),
  },
  methods: {
    loginRequired() {
      if (!this.currentUser && this.$route.path !== "/") {
        this.$router.push("/?redirect=" + this.$route.path);
      }
    },
    loadFrontendConfig(allowFromCache: boolean) {
      const defaultBaseURL = `${window.location.origin}/api`;
      const configURL =
        process.env.NODE_ENV === "production"
          ? "/ui/frontend_config.json"
          : "/frontend_config.json";
      if (allowFromCache && this.vueFrontendConfig !== undefined) {
        this.$http.defaults.baseURL =
          this.vueFrontendConfig.api !== undefined
            ? this.vueFrontendConfig.api
            : defaultBaseURL;
      } else {
        this.$http
          .get(`${window.location.origin}${configURL}`)
          .then((response) => {
            let jsonResponseData;
            try {
              jsonResponseData =
                typeof response.data === "string"
                  ? JSON.parse(response.data)
                  : response.data;
            } catch (e) {
              jsonResponseData = { error: `${e}` };
            }
            this.$store.dispatch("configureVue", jsonResponseData);
            this.$http.defaults.baseURL =
              response.data.api !== undefined
                ? response.data.api
                : defaultBaseURL;
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log(`Error(${err} - reading frontend_config.json)`);
            this.$http.defaults.baseURL = defaultBaseURL;
          });
      }
    },
  },
  created() {
    this.loadFrontendConfig(false);
    this.loginRequired();
  },
}).$mount("#app");
