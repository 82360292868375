<template>
  <v-container>
    <!-- Left Navigation -->
    <v-navigation-drawer v-model="drawer" fixed clipped app>
      <v-list v-for="itemList in leftNavDrawerItems" :key="itemList.key" dense>
        <v-list-item-group>
          <!--<v-subheader>{{ itemList.title }}</v-subheader>-->
          <!-- Route Group -->
          <v-list-item
            v-for="(item, i) in itemList.items"
            :key="i"
            :to="{ path: item.path }"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider />
      </v-list>
    </v-navigation-drawer>

    <!-- Top Application Bar -->
    <v-app-bar app color="primary" dark clipped-left fixed>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title
        ><router-link to="/home"
          ><img
            src="@/assets/PROG_WHITE.png"
            style="height: 40px; padding-top: 8px" /></router-link
      ></v-toolbar-title>

      <v-spacer />
      <div>
        {{ currentUser.sub }}
      </div>
      <v-avatar size="32px" style="margin-left: 12px" color="secondary"
        ><span class="white--text">{{
          currentUser.sub.charAt(0).toUpperCase()
        }}</span></v-avatar
      >
      <!-- <v-list>
          <v-list-tile :to="{ path: '/account' }">
            <v-list-tile-title>Account</v-list-tile-title>
          </v-list-tile>
          <v-list-tile :to="{ path: '/logout' }">
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile>
        </v-list> -->
    </v-app-bar></v-container
  >
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({ drawer: null }),
  props: {
    leftNavDrawerItems: {
      type: Array,
      default: function () {
        return [{ title: "Home", icon: "home", path: "/" }];
      },
    },
  },
  name: "ToolBar",
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
  },
};
</script>
