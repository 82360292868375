import JwtDecode from "jwt-decode";
import moment from "moment";

import { UserOperations } from "@/UserOperations";

export default class User {
  static from(token) {
    try {
      let obj = JwtDecode(token);
      const newUser = new User(obj);
      return newUser;
    } catch (_) {
      return null;
    }
  }

  constructor({ exp, iat, sub, operations = undefined }) {
    this.exp = exp;
    this.iat = iat;
    this.sub = sub;
    this.operations = operations;
  }

  get username() {
    console.log("Getting username");
    console.log(this.sub);
    return this.sub;
  }

  get avatarFirstLetter() {
    return this.sub.charAt(0).toUpperCase();
  }

  get expISO() {
    return moment.unix(this.exp).toISOString();
  }
  get iatISO() {
    return moment.unix(this.iat).toISOString();
  }

  hasOperation(operation) {
    for (let op of this.operations) {
      if (op === UserOperations.AllOperations) {
        return true;
      }
      if (op === operation) {
        return true;
      }
    }
    return false;
  }
}
