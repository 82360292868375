import * as MutationTypes from "@/store/mutation-types";

const state = {
  vueFrontendConfig: undefined,
};

const getters = {
  vueFrontendConfig: (state) => state.vueFrontendConfig,
};

const mutations = {
  [MutationTypes.CONFIGURE](state, configObject) {
    state.vueFrontendConfig = configObject;
  },
};

const actions = {
  configureVue({ commit }, configObject) {
    commit(MutationTypes.CONFIGURE, configObject);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
