<template>
  <v-footer color="primary" class="white--text" app>
    <v-spacer></v-spacer>
    <span
      >&copy; {{ new Date().getFullYear() }} -
      <strong>Barter For Things</strong></span
    >
    <v-spacer></v-spacer>
  </v-footer>
</template>

<script>
export default {
  name: "FooterLayout",
};
</script>
