export const UserOperations = {
  AllOperations: 0,

  // Create alert groups etc
  ManageAlertActions: 1,

  // Read Assetinfo, GroupInfo, Location, etc (Usually restricted Admin only)
  ReadManifest: 2,

  // Create, Update, Delete Assetinfo, GroupInfo, Location, etc (Usually restricted Admin only)
  ManageManifest: 3,

  // Read the list of installed devices and who installed them, and when
  ReadInstallInfo: 4,

  // Manage the list of install info (E.g. manaual adding if the app was not used to install the device)
  ManageInstallInfo: 5,

  // Just allows reading of the asset list (E.g. for a mobile user) but not install devices
  ReadAssetLocations: 6,

  // Mobile/install app endpoints
  InstallApp: 7,

  // Allows the use upload reports (Report API)
  UploadReport: 8,

  // Download reports (Report API)
  DownloadReport: 9,

  // Delete reports (Report API)
  DeleteReport: 10,

  ReadDeviceHeirarchy: 11,
  ManageDeviceHeirarchy: 12,

  ManageDeviceStatus: 13,
  ManageDeviceStatusConnectivity: 14,
  ManageSigfoxBaseStation: 15,

  // This includings exporting the CSV file
  ReadDeviceMessages: 16,

  RegisterUsers: 17,
  ReadUsers: 18,
  ManageUsers: 19,
};
