import L from "leaflet";

/**
 *  This is a work-around to Leaflet typings not supporting the `Default.prototype._getIconUrl` definition, therefore not allowing deletion of the method.
 *  This must be called before any other Leaflet code is executed.
 *
 *  This must be kept as JS file called from main.ts as is
 */

export default function setUpLeaflet() {
  delete L.Icon.Default.prototype._getIconUrl;

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
}
