import Vue from "vue";
import moment from "moment";

Vue.filter(
  "prettyPrintAPIDate",
  function (value, format = "DD/MM/YYYY HH:mm:ss") {
    if (!value) return "";
    return moment(value, "YYYYMMDDHHmmss").format(format);
  }
);
