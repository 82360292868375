import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";

Vue.use(Router);

function loadComponent(component) {
  return () =>
    import(
      /* webpackChunkName: "component-[request]" */ `@/components/${component}.vue`
    );
}

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "LoginPage",
      component: loadComponent("Auth/LoginPage"),
    },
    {
      path: "/logout",
      name: "Logout",
      component: loadComponent("Auth/LogoutPage"),
    },
    {
      path: "/account",
      name: "AccountPage",
      component: loadComponent("Auth/AccountPage"),
      b4tAdminRequired: true,
    },
    {
      path: "/home",
      name: process.env.VUE_APP_HOME_VUE,
      component: loadComponent(`Home/${process.env.VUE_APP_HOME_VUE}`),
    },
    {
      path: "/export",
      name: "DataExport",
      component: loadComponent("Export/DataExport"),
    },
    {
      path: "/graph",
      name: "GraphSelectForm",
      component: loadComponent("Graph/GraphSelectForm"),
    },
    {
      path: "/graph/devicetype/:deviceID",
      name: "DeviceTypeGraph",
      component: loadComponent("Graph/DeviceTypeGraph"),
    },
    {
      path: "/organisations",
      name: "OrganisationList",
      component: loadComponent("Models/Organisation/List"),
    },
    {
      path: "/organisations/:id",
      name: "OrganisationSingle",
      component: loadComponent("Models/Organisation/Single"),
    },
    {
      path: "/projectgroups",
      name: "ProjectGroupList",
      component: loadComponent("Models/ProjectGroup/List"),
    },
    {
      path: "/projectgroups/:id",
      name: "ProjectGroupSingle",
      component: loadComponent("Models/ProjectGroup/Single"),
    },
    {
      path: "/devicetypes",
      name: "DeviceTypeList",
      component: loadComponent("Models/DeviceType/List"),
    },
    {
      path: "/devicetypes/:id",
      name: "DeviceTypeSingle",
      component: loadComponent("Models/DeviceType/Single"),
    },
    {
      path: "/devices",
      name: "DeviceList",
      component: loadComponent("Models/Device/List"),
    },
    {
      path: "/changepassword",
      name: "ChangePassword",
      component: loadComponent("Auth/ChangePassword"),
      b4tAdminRequired: true,
    },

    //
    // Alerts and Actions
    //
    {
      path: "/alertgroup",
      name: "AlertGroup",
      component: loadComponent("AlertActions/AlertGroup"),
      b4tAdminRequired: true,
    },

    //
    //   Manifest
    //
    {
      path: "/groupinfos",
      name: "GroupInfos",
      component: loadComponent("Models/GroupInfo/List"),
      b4tAdminRequired: true,
    },
    {
      path: "/groupinfos/:id",
      name: "GroupSingle",
      component: loadComponent("Models/GroupInfo/Single"),
      b4tAdminRequired: true,
    },

    {
      path: "/assetinfos",
      name: "Asset Info",
      component: loadComponent("Models/Assetinfo/List"),
      b4tAdminRequired: true,
    },
    {
      path: "/assetinfos/:id",
      name: "AssetSingle",
      component: loadComponent("Models/Assetinfo/Single"),
      b4tAdminRequired: true,
    },
    {
      path: "/physicalinfos",
      name: "Physical Info",
      component: loadComponent("Models/Physicalinfo/List"),
      b4tAdminRequired: true,
    },
    {
      path: "/devicestatus",
      name: "Device Status",
      component: loadComponent("Models/DeviceStatus/DeviceStatus"),
    },
  ].filter((route) => {
    if (!store.getters.vueFrontendConfig) {
      return true;
    }
    if (!store.getters.vueFrontendConfig.b4tadmin) {
      return !route.b4tAdminRequired;
    }
    return true;
  }),
});
