import axios from "./axios";
import store from "@/store";
import router from "@/router";

// Configure Axios Interceptors
export default function configureInterceptors() {
  // Request Interceptor
  axios.interceptors.request.use(
    function (request) {
      // Grab the token from the Vuex store.
      const token = store.getters.token;
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }

      return request;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Response Interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        store.dispatch("logout");
        router.push("/?message=Session Expired - Please login again!");
      } else {
        return Promise.reject(error);
      }
    }
  );
}
