import User from "@/models/User";
import * as MutationTypes from "@/store/mutation-types";

import { UserOperations } from "@/UserOperations";

const state = {
  session: {
    currentUser: null,
    loggedIn: false,
    token: "",
  },
};

const getters = {
  /**
   * Returns true if the user has the given operation
   * @param {UserRole} operation
   * @returns {boolean} true if the user has the operation
   */
  userPermitted: (state) => (operation) => {
    if (state.session.currentUser) {
      return hasOperation(state.session.currentUser.operations, operation);
    }
    return false;
  },

  /// Returns the current user
  currentUser: (state) => state.session.currentUser,

  /// Returns true if the user is logged in
  loggedIn: (state) => state.session.loggedIn,

  /// Returns the current user's token
  token: (state) => state.session.token,
};

const mutations = {
  [MutationTypes.LOGIN](state, token) {
    state.session.currentUser = User.from(token);
    state.session.loggedIn = true;
    state.session.token = token;
  },
  [MutationTypes.LOGOUT](state) {
    state.session.currentUser = null;
    state.session.loggedIn = false;
    state.session.token = "";
  },
  [MutationTypes.SET_OPERATIONS](state, operations) {
    state.session.currentUser.operations = operations;
  },
};

const actions = {
  login({ commit }, token) {
    commit(MutationTypes.LOGIN, token);
  },

  logout({ commit }) {
    commit(MutationTypes.LOGOUT);
  },

  setOperations({ commit }, operations) {
    commit(MutationTypes.SET_OPERATIONS, operations);
  },
};

function hasOperation(operations, operation) {
  for (let op of operations) {
    if (op === UserOperations.AllOperations) {
      return true;
    }
    if (op === operation) {
      return true;
    }
  }
  return false;
}

export default {
  state,
  mutations,
  getters,
  actions,
};
