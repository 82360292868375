<template>
  <v-app>
    <template v-if="currentUser && currentUser.operations">
      <HeaderLayout />
      <v-main>
        <router-view />
      </v-main>
      <FooterLayout />
    </template>
    <template v-else>
      <v-main>
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderLayout from "@/components/Layout/HeaderLayout";
import FooterLayout from "@/components/Layout/FooterLayout";
export default {
  name: "App",
  components: {
    HeaderLayout,
    FooterLayout,
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
  },
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
