<template>
  <div>
    <ToolBar :leftNavDrawerItems="leftNavDrawerItemsFiltered" />
  </div>
</template>

<script>
import ToolBar from "./ToolBar";
import { mapGetters } from "vuex";
import { UserOperations } from "@/UserOperations";

export default {
  name: "HeaderLayout",
  components: { ToolBar },
  computed: {
    leftNavDrawerItemsFiltered() {
      const predicate = (item) => {
        // Check if the user has the required permission
        if (
          item.operationRequired &&
          !this.userPermitted(item.operationRequired)
        ) {
          return false;
        }
        return true;
      };

      return this.leftNavDrawerItems.filter(predicate).map((route) => {
        return { ...route, items: route.items.filter(predicate) };
      });
    },
    ...mapGetters({ userPermitted: "userPermitted" }),
  },
  data: () => ({
    // Left Nav Items
    leftNavDrawerItems: [
      {
        key: "basic-nav",
        title: "Basic Navigation",
        items: [
          {
            title: "Home",
            icon: "home",
            path: "/home",
            operationRequired: null,
          },
          {
            title: "Data Export",
            icon: "cloud_download",
            path: "/export",
            operationRequired: UserOperations.ReadDeviceMessages,
          },
          {
            title: "Device Graph",
            icon: "insert_chart",
            path: "/graph",
            operationRequired: UserOperations.ReadDeviceMessages,
          },
          {
            title: "Device Status",
            icon: "speed",
            path: "/devicestatus",
            operationRequired: UserOperations.ManageDeviceStatus,
          },
        ],
      },
      {
        key: "devices",
        title: "Devices",
        items: [
          {
            title: "Organisations",
            icon: "phonelink",
            path: "/organisations",
          },
          {
            title: "Project Groups",
            icon: "phonelink",
            path: "/projectgroups",
          },
          {
            title: "Device Types",
            icon: "phonelink",
            path: "/devicetypes",
          },
          {
            title: "Devices",
            icon: "phonelink",
            path: "/devices",
          },
        ],
        operationRequired: UserOperations.ReadDeviceHeirarchy,
      },
      {
        key: "alert-actions",
        title: "Alerts & Actions",
        items: [
          {
            title: "Alert Groups",
            icon: "bolt",
            path: "/alertgroup",
          },
        ],
        operationRequired: UserOperations.ManageAlertActions,
        b4tAdminRequired: true,
      },
      {
        key: "manifest",
        title: "Manifest",
        items: [
          {
            title: "Group Info",
            icon: "phonelink",
            path: "/groupinfos",
          },
          {
            title: "Asset Info",
            icon: "phonelink",
            path: "/assetinfos",
          },
          {
            title: "Physical Info",
            icon: "phonelink",
            path: "/physicalinfos",
          },
        ],
        operationRequired: UserOperations.ReadManifest,
        b4tAdminRequired: true,
      },
      // {
      //  key: 'admin',
      //  admin: true,
      //  items: [
      //    {
      //      title: 'Sigfox Dashboard',
      //      icon: 'monitor',
      //      path: '/sigfox_dashboard'
      //    }
      //  ]
      // },
      {
        key: "account",
        title: "Account",
        items: [
          {
            title: "Account Page",
            icon: "perm_identity",
            path: "/account",
            operationRequired: UserOperations.ManageUsers,
            b4tAdminRequired: true,
          },
          {
            title: "Logout",
            icon: "exit_to_app",
            path: "/logout",
            operationRequired: null,
          },
        ],
      },
    ],
  }),
};
</script>
